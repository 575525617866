<template>
  <div class="breadcrumbs">
    <div class="content">
      <div class="breadcrumbs__row">
        <router-link
          :to="{ name: 'MainPage' }"
          class="breadcrumbs__item breadcrumbs__item_main"
          >Главная</router-link
        >
        <div class="breadcrumbs__item" v-for="(crumb, ind) in pages" :key="ind">
          <div class="breadcrumbs__separator mx-3">/</div>
          <router-link v-if="crumb.link" :to="crumb.link" v-html="crumb.name"></router-link>
          <div v-else class="breadcrumbs__item-name" :style="`color: ${color}`" v-html="crumb.name"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    color: String,
    pages: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  padding-top: 32px;
  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
     -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
  }

  &__separator {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #b2b4b4;
  }

  &__item {
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #830051;
    overflow: hidden;
    color: #b2b4b4;
    transition: .3s;
    &:last-child {
      color: #830051;
      // flex-shrink: 1;
    }

    &:hover {
      color: #830051;
    }

    @media screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 14px;
    }

    &:last-child {
      margin-right: 0;
    }

    &_main {
      color: #b2b4b4;
      flex-shrink: 0;
    }
  }

  &__item-name {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 850px;

    & > br {
      display: none;
    }
  }
}
</style>

<style>
 .breadcrumbs__item-name br {
  display: none;
 }
</style>